/** @jsx jsx */
import { jsx } from 'theme-ui';

const Container = ({ children, ...props }) => (
  <div
    {...props}
    sx={{
      position: 'relative',
      maxWidth: '964px',
      mx: 'auto',
      px: 7,
      '@media (max-width: 400px)': {
        px: 6,
      },
    }}
  >
    {children}
  </div>
);

export default Container;
