/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import createGet from '../utils/create-get';

import homeIntro2Svg from '../img/illustrations/home-intro-2.svg';
import homeActivitiesSvg from '../img/illustrations/home-activities.svg';
import homeInnovationSvg from '../img/illustrations/home-innovation.svg';

import homeBrainSmallSvg from '../img/illustrations/home-brain-small.svg';
// import homeBrainMediumSvg from '../img/illustrations/home-brain-medium.svg';
import homeBrainLargeSvg from '../img/illustrations/home-brain-large.svg';

import MarkdownContent from '../components/MarkdownContent';
import Layout from '../components/Layout';
import Container from '../components/Container';
import { Button } from '../components/Buttons';
import List from '../components/List';
import Callout from '../components/Callout';
import AspectRatioImg from '../components/AspectRatioImg';

import ArticleCard from '../components/ArticleCard';
import MiniArticleCard from '../components/MiniArticleCard';

const Intro = ({ get }) => (
  <Container>
    <div
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', null, '1.2fr 1fr'],
        gridGap: 8,
      }}
    >
      <div>
        <Styled.h1 sx={{ whiteSpace: 'pre-line' }}>
          {get('intro.title')}
        </Styled.h1>
        <MarkdownContent
          sx={{
            mt: 9,
            em: { fontStyle: 'normal', color: 'secondary' },
          }}
        >
          {get('intro.text')}
        </MarkdownContent>
        <Button
          to={get('intro.cta.path')}
          variant="secondary"
          sx={{
            maxWidth: [null, null, '320px'],
            mt: 9,
          }}
          outlined
        >
          {get('intro.cta.label')}
        </Button>
      </div>
      <div sx={{ display: ['none', null, 'block'] }}>
        <AspectRatioImg src={homeIntro2Svg} alt="" ratio={404 / 505} />
      </div>
    </div>
  </Container>
);

const Benefits = ({ get }) => (
  <Container sx={{ my: 14 }}>
    <Styled.h2>{get('benefits.title')}</Styled.h2>
    <List slim rounded sx={{ mt: 7 }}>
      {get('benefits.list', []).map((item, i) => (
        <li key={i}>
          <MarkdownContent>{item}</MarkdownContent>
        </li>
      ))}
    </List>
  </Container>
);

const Activities = ({ get }) => (
  <Container sx={{ my: 14 }}>
    <div
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', null, '1fr 2fr'],
        gridGap: 7,
      }}
    >
      <div
        sx={{
          display: ['none', null, 'flex'],
          justifyContent: 'flex-end',
        }}
      >
        <AspectRatioImg
          src={homeActivitiesSvg}
          alt=""
          ratio={283 / 499}
          maxWidth={283}
          width="100%"
        />
      </div>
      <div>
        <Styled.h2>{get('activities.title')}</Styled.h2>
        <List slim rounded sx={{ mt: 7 }}>
          {get('activities.list', []).map((item, i) => (
            <li key={i}>
              <MarkdownContent>{item}</MarkdownContent>
            </li>
          ))}
        </List>
        <Button
          to={get('activities.cta.path')}
          variant="secondary"
          sx={{
            maxWidth: [null, null, '320px'],
            mt: 9,
          }}
          outlined
        >
          {get('activities.cta.label')}
        </Button>
      </div>
    </div>
  </Container>
);

const Latest = ({ get, latestPosts, latestTech }) => (
  <Container sx={{ my: 14 }}>
    <h2
      sx={{
        fontSize: '21px',
        fontWeight: 'bold',
        lineHeight: 'body',
        mb: 7,
      }}
    >
      {get('latest.title')}
    </h2>
    <div
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gridGap: 7,
      }}
    >
      {latestPosts.slice(0, 2).map((post) => (
        <ArticleCard key={post.id} post={post} />
      ))}
      <div sx={{ display: 'grid', gridGap: 7 }}>
        {latestPosts.slice(2, 6).map((post) => (
          <MiniArticleCard key={post.id} post={post} />
        ))}
      </div>
      {latestTech && <ArticleCard post={latestTech} />}
    </div>
  </Container>
);

const Inspiration = ({ get }) => (
  <Container sx={{ mt: 14 }}>
    <Callout
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 1fr'],
        gridGap: '64px',
        alignItems: 'center',
        justifyItems: 'center',
      }}
    >
      <div sx={{ order: [null, '1'] }}>
        <div sx={{ display: ['block', null, 'none'] }}>
          <AspectRatioImg
            src={homeBrainSmallSvg}
            alt=""
            ratio={336 / 462}
            width="250px"
          />
        </div>
        <div sx={{ display: ['none', null, 'block'] }}>
          <AspectRatioImg
            src={homeBrainLargeSvg}
            alt=""
            ratio={443 / 347}
            width="400px"
          />
        </div>
      </div>
      <div>
        <Styled.h2>{get('inspiration.title')}</Styled.h2>
        <MarkdownContent sx={{ mt: 7 }}>
          {get('inspiration.text')}
        </MarkdownContent>
      </div>
    </Callout>
  </Container>
);

const Innovation = ({ get }) => (
  <Container sx={{ mt: 14 }}>
    <div
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', null, '1fr 2fr'],
        gridGap: 7,
      }}
    >
      <div
        sx={{
          display: ['none', null, 'flex'],
          justifyContent: 'flex-end',
        }}
      >
        <AspectRatioImg
          src={homeInnovationSvg}
          alt=""
          ratio={283 / 499}
          maxWidth={283}
          width="100%"
        />
      </div>
      <div>
        <Styled.h2>{get('innovation.title')}</Styled.h2>
        <List rounded slim sx={{ mt: 7 }}>
          {get('innovation.list', []).map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </List>
        <Button
          to={get('innovation.cta.path')}
          variant="secondary"
          sx={{
            maxWidth: [null, null, '320px'],
            mt: 9,
          }}
          outlined
        >
          {get('innovation.cta.label')}
        </Button>
      </div>
    </div>
  </Container>
);

export const IndexPageTemplate = (props) => (
  <Fragment>
    <Intro {...props} />
    <Benefits {...props} />
    <Activities {...props} />
    <Latest {...props} />
    <Inspiration {...props} />
    <Innovation {...props} />
  </Fragment>
);

const IndexPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
    latestPosts,
    latestTech,
  } = data;

  return (
    <Layout
      page="home"
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <Helmet titleTemplate={null} />
      <IndexPageTemplate
        get={createGet(frontmatter)}
        latestPosts={latestPosts.nodes}
        latestTech={latestTech.nodes[0]}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery($id: String!, $language: String!, $currentDate: Date!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
        intro {
          title
          text
          cta {
            label
            path
          }
        }
        activities {
          title
          list
          cta {
            label
            path
          }
        }
        latest {
          title
        }
        innovation {
          title
          list
          cta {
            label
            path
          }
        }
        inspiration {
          title
          text
        }
        benefits {
          title
          list
          cta {
            label
            path
          }
        }
      }
    }
    latestPosts: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 5
      filter: {
        fields: { sourceInstanceName: { in: ["blog-posts", "news-posts"] } }
        frontmatter: {
          language: { eq: $language }
          date: { lte: $currentDate }
        }
      }
    ) {
      nodes {
        id
        slug
        fields {
          url
          sourceInstanceName
        }
        frontmatter {
          title
          language
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        timeToRead
      }
    }
    latestTech: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 1
      filter: {
        fields: { sourceInstanceName: { eq: "tech-posts" } }
        frontmatter: {
          language: { eq: $language }
          date: { lte: $currentDate }
        }
      }
    ) {
      nodes {
        id
        slug
        fields {
          url
          sourceInstanceName
        }
        frontmatter {
          title
          subtitle
          language
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        timeToRead
      }
    }
  }
`;
